export interface DashboardItem {
    name: string;
    description: string;
    navigationPath: string;
    backgroundColor: string;
    countIndicator?: string;
}

export const dashboardItems: DashboardItem[] = [
    {
        name: "SR",
        description: "Schlüssel- risiken",
        navigationPath: "/risk",
        backgroundColor: "#424b51",
    },
    {
        name: "SK",
        description: "Schlüssel- kontrollen",
        navigationPath: "/control",
        backgroundColor: "#209042",
    },
    {
        name: "AW",
        description: "A&W Prüfung",
        navigationPath: "/report",
        backgroundColor: "#424b51",
    },
    {
        name: "DS",
        description: "Durchführ. SchlüKontr",
        navigationPath: "/ds",
        backgroundColor: "#424b51",
        countIndicator: "+99",
    },
    {
        name: "RE",
        description: "Regulato- rien",
        navigationPath: "/document",
        backgroundColor: "#7f2090",
    },
    {
        name: "VP",
        description: "Verantw. Personen",
        navigationPath: "/user",
        backgroundColor: "#207a90",
    },
];
