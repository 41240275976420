import React from "react";
import { AppFooter, AppHeader, Page } from "../common";
import { FlexCell } from "@epam/promo";
import { IntroBlock } from "./IntroBlock";

export class LandingPage extends React.Component {
    constructor(props: {}) {
        super(props);
    }

    renderHeader = () => {
        return <AppHeader logoUrl="logo-b2.png" />;
    };

    renderFooter = () => {
        return <AppFooter />;
        // return <></>;
    };

    render() {
        return (
            <Page
                renderHeader={this.renderHeader}
                renderFooter={this.renderFooter}
                isLoginPage={true}
            >
                <FlexCell cx="landing" width="100%">
                    <IntroBlock />
                </FlexCell>
            </Page>
        );
    }
}
