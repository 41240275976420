import React, { useEffect, useState } from "react";
import {
    Anchor,
    FlexCell,
    FlexRow,
    IconContainer,
    Text,
    FilterPickerBody,
    Panel,
    TabButton,
    Badge,
    Tooltip,
    Button,
    LabeledInput,
    TextInput,
    NumericInput,
    Switch,
    DatePicker,
    TimePicker,
    SuccessAlert,
    SuccessNotification,
    DropdownMenuBody,
    DropdownMenuButton,
    Dropdown,
    FlexSpacer,
    BadgeProps,
    IconButton,
    useForm,
    HintNotification,
    Blocker,
    ErrorNotification,
} from "@epam/uui";
import {
    DataQueryFilter,
    DropdownBodyProps,
    INotification,
    useArrayDataSource,
    useAsyncDataSource,
    useLazyDataSource,
    useUuiContext,
    UuiContexts,
} from "@epam/uui-core";
import {
    AppUserConfig,
    AuthenticateRequest,
    TApi,
} from "src/data/apiDefinition";

import { useAppUser } from "../helpers/appUser";

import css from "./IntroBlock.module.scss";

export function IntroBlock() {
    const ERROR_MESSAGES = {
        EMAIL_NOT_VALID: "Nicht gültige E-Mail",
        EMAIL_IS_REQUIRED: "Die Nutzer E-Mail fehlt.",
        PASSWORD_IS_REQUIRED: "Das Passwort muss eingegeben werden.",
        LOGIN_ERROR: "Die Login-Daten sind nicht korrekt.",
    };

    const svc = useUuiContext<TApi, UuiContexts>();
    const [isLoading, setIsLoading] = useState(false);
    const [switchRememberMeValue, setSwitchRememberMeValue] = useState(false);

    const userContext = useAppUser();

    useEffect(() => {
        var token = userContext?.user?.token;
        if (token) {
            svc.uuiRouter.redirect("/dashboard");
        }
    });
    var setUserContext = (user: AppUserConfig) => {
        userContext?.authenticate(user);
    };

    const { lens, save } = useForm<AuthenticateRequest>({
        value: { email: "", password: "" },
        getMetadata: () => ({
            props: {
                email: {
                    validators: [
                        (value, user) => [
                            !value && ERROR_MESSAGES.EMAIL_IS_REQUIRED,
                        ],
                        // (value, user) => [
                        //     !(value && value.includes("@")) &&
                        //         ERROR_MESSAGES.EMAIL_NOT_VALID,
                        // ],
                    ],
                },
                password: {
                    validators: [
                        (value, user) => [
                            !value && ERROR_MESSAGES.PASSWORD_IS_REQUIRED,
                        ],
                    ],
                },
            },
        }),
        onSave: async (formValue) => {
            setIsLoading(true);
            svc.api
                .authenticateUser(formValue)
                .then((response) => {
                    setUserContext(response);
                    svc.uuiRouter.redirect({ pathname: "/dashboard" });
                })
                .catch((error) => {
                    console.error("Cannot login the user", error);
                    setIsLoading(false);
                    svc.uuiNotifications
                        .show((props) => (
                            <ErrorNotification {...props}>
                                <Text>{ERROR_MESSAGES.LOGIN_ERROR}</Text>
                            </ErrorNotification>
                        ))
                        .catch(() => {});
                });
        },
        onError: () => {
            setIsLoading(false);
            return svc.uuiNotifications
                .show(
                    (props) => (
                        <ErrorNotification {...props}>
                            <Text>{ERROR_MESSAGES.LOGIN_ERROR}</Text>
                        </ErrorNotification>
                    ),
                    { position: "bot-left" },
                )
                .catch(() => {});
        },
    });

    return (
        <div className={css.root}>
            <div className={css.container}>
                <div className={css.wrapper}>
                    <Blocker isEnabled={isLoading} />
                    <Panel
                        shadow={false}
                        cx={css.componentsMiddleWrapper}
                        style={{ padding: "10px" }}
                    >
                        <FlexRow columnGap="12" cx={css.middleRow}>
                            <FlexCell grow={0.2}>
                                <TextInput
                                    cx={css.middleRowText}
                                    size="30"
                                    placeholder="E-Mail"
                                    isDisabled={isLoading}
                                    {...lens.prop("email").toProps()}
                                />
                            </FlexCell>
                            <FlexCell grow={0.2}>
                                <TextInput
                                    cx={css.middleRowText}
                                    size="30"
                                    type="password"
                                    placeholder="Passwort"
                                    isDisabled={isLoading}
                                    {...lens.prop("password").toProps()}
                                />
                            </FlexCell>
                            <FlexCell>
                                <Button
                                    fill="outline"
                                    caption="Login"
                                    size="30"
                                    onClick={save}
                                    isDisabled={isLoading}
                                />
                            </FlexCell>
                        </FlexRow>
                        <FlexRow>
                            <FlexCell grow={0.3}>
                                <Switch
                                    label="Dieses Login wiedererkennen?"
                                    value={switchRememberMeValue}
                                    onValueChange={setSwitchRememberMeValue}
                                />
                            </FlexCell>
                            <FlexCell grow={0.2}>
                                <Text>Passwort vergessen?</Text>
                            </FlexCell>
                        </FlexRow>
                    </Panel>
                    <div className={css.companyInfo}>
                        <Panel>
                            <FlexRow>
                                <Text cx={css.text}>
                                    Mit <b>IKS online</b> haben wir das Interne
                                    Kontrollsystem (IKS) komplett digitalisiert
                                    und automatisiert. Wir haben Wert darauf
                                    gelegt, dass Sie Ihre Routine mit
                                    tabellarischen Darstellungen, bspw. Excel,
                                    beibehalten können. Unsere nach
                                    bankengesetzlichen Vorschriften
                                    zertifizierte Lösung erlaubt die Erfassung,
                                    Prüfung, Kontrolle und das Reporting voll
                                    automatisiert, jederzeit und ortsunabhängig.
                                    Mit wenigen Klicks.
                                </Text>
                            </FlexRow>
                            <FlexRow>
                                <Text cx={css.text}>
                                    Kontaktieren Sie uns und wir präsentieren
                                    Ihnen die voll lauffähige und bereits im
                                    Einsatz befindliche Lösung persönlich.
                                </Text>
                            </FlexRow>
                            <div className={css.addressBlock}>
                                <FlexRow>
                                    <FlexCell grow={0.4}>
                                        <div className={css.text}>
                                            <b>Law Tech AG</b>
                                        </div>
                                        <div className={css.text}>
                                            Kesslerstrasse 1
                                        </div>
                                        <div className={css.text}>
                                            9000 St. Gallen
                                        </div>
                                        <br />
                                        <div className={css.text}>
                                            +41 71 220 33 10
                                        </div>
                                        <div className={css.text}>
                                            kontakt@law-teq.ch
                                        </div>
                                    </FlexCell>
                                    <FlexCell grow={0.4}>
                                        <div className={css.text}>
                                            <b>Equilas AG</b>
                                        </div>
                                        <div className={css.text}>
                                            Genfergasse 8
                                        </div>
                                        <div className={css.text}>
                                            3011 Bern
                                        </div>
                                        <br />
                                        <div className={css.text}>
                                            +41 58 748 44 00
                                        </div>
                                        <div className={css.text}>
                                            info@equilas.ch
                                        </div>
                                    </FlexCell>
                                </FlexRow>
                            </div>
                        </Panel>
                    </div>
                </div>
            </div>
        </div>
    );
}
