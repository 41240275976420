import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import {
    Router6AdaptedRouter,
    useUuiServices,
    UuiContext,
    IProcessRequest,
} from "@epam/uui-core";

import { svc } from "./services";
import { getApi, TApi } from "./data/apiDefinition";
import { AppContext, getThemeContext } from "./data/appContext";
import { getAppRootNode } from "./helpers/appRootUtils";

import App from "./App";

import "@epam/uui-components/styles.css";
import "@epam/uui/styles.css";
import "@epam/assets/theme/theme_loveship.scss";
import "./index.module.scss";

const router6 = createBrowserRouter([{ path: "*", element: <App /> }]);
const router = new Router6AdaptedRouter(router6);

function apiDefinition(processRequest: IProcessRequest) {
    return getApi({ processRequest, fetchOptions: { credentials: undefined } });
}

function IksUiApp() {
    const [isLoaded, setIsLoaded] = useState(false);
    const { services } = useUuiServices<TApi, AppContext>({
        apiDefinition,
        router,
        apiServerUrl: process.env.REACT_APP_API_URL,
        apiReloginPath: "api/auth/login",
        apiPingPath: "api/healthz",
    });

    useEffect(() => {
        // [REV2][MAY: check why]
        Object.assign(svc, services);
        async function initServices() {
            const themeContext = await getThemeContext();
            services.uuiApp = { ...themeContext };
            setIsLoaded(true);
        }
        initServices();
    }, [services, isLoaded]);

    if (isLoaded) {
        return (
            <UuiContext.Provider value={services}>
                <RouterProvider router={router6} />
            </UuiContext.Provider>
        );
    }
    return null;
}

function initApp() {
    const rootNode = getAppRootNode() as HTMLElement;
    const root = createRoot(rootNode);
    root.render(
        <React.StrictMode>
            <IksUiApp />
        </React.StrictMode>,
    );
}

initApp();
