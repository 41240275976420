import React, { useState } from "react";
import cx from "classnames";
import { IHasChildren, isMobile } from "@epam/uui-core";
import {
    AdaptiveItemProps,
    Anchor,
    MainMenuCustomElement,
} from "@epam/uui-components";
import {
    Burger,
    BurgerButton,
    Button,
    Dropdown,
    DropdownMenuBody,
    DropdownMenuButton,
    FlexSpacer,
    GlobalMenu,
    IconContainer,
    MainMenu,
    MainMenuButton,
    MultiSwitch,
    Text,
    FlexRow,
    DropdownMenuHeader,
    MainMenuDropdown,
    BurgerGroupHeader,
    DropdownMenuSplitter,
    MainMenuAvatar,
} from "@epam/uui";
import { svc } from "../services";

import { ReactComponent as LogoIcon } from "../icons/logo-iksonline.svg";
import css from "./AppHeader.module.scss";
import { AppUserProfile } from "../data/apiDefinition";

export interface AppHeaderProps extends IHasChildren {
    logoUrl?: string;
    userProfile?: AppUserProfile | null;
    userProfileClear?: () => void;
}

export function AppHeader(props: AppHeaderProps) {
    const { logoUrl, userProfile, userProfileClear } = props;
    const renderBurger = () => {
        return (
            <>
                <BurgerButton caption="Home" link={{ pathname: "/" }} />
                {/* <BurgerButton
                    href="/"
                    caption="Log out"
                    onClick={() => {
                        console.log("I am in logout");
                    }}
                /> */}
            </>
        );
    };

    const renderAvatar = () => {
        if (userProfile)
            return (
                <Dropdown
                    key="avatar"
                    renderTarget={(props) => (
                        <MainMenuAvatar
                            avatarUrl="https://api.dicebear.com/9.x/initials/svg?seed=FL&radius=50"
                            isDropdown
                            {...props}
                        />
                    )}
                    renderBody={(props) => (
                        <DropdownMenuBody {...props}>
                            <DropdownMenuButton caption="Settings" />
                            <DropdownMenuSplitter />
                            <DropdownMenuButton
                                caption="Log out"
                                onClick={() => {
                                    if (userProfileClear) userProfileClear();
                                }}
                            />
                        </DropdownMenuBody>
                    )}
                    placement="bottom-end"
                />
            );

        return <></>;
    };

    const getMainMenuItems = () => {
        // const category = svc.uuiRouter?.getCurrentLink().query.category;
        // const pathName = svc.uuiRouter?.getCurrentLink().pathname;

        return [
            {
                id: "burger",
                priority: 100500,
                collapsedContainer: true,
                render: () => (
                    <Burger
                        renderBurgerContent={renderBurger}
                        logoUrl={`/static/${logoUrl ?? "logo-b2.png"}`}
                        key="burger"
                    />
                ),
            },
            {
                id: "logo",
                priority: 100499,
                render: () => (
                    <MainMenuCustomElement key="logo">
                        <Anchor link={{ pathname: "/" }}>
                            <img
                                src={`/static/${logoUrl ?? "logo-b2.png"}`}
                                alt="IKSOnline"
                                style={{ padding: "20px", marginTop: "20px" }}
                            ></img>
                        </Anchor>
                    </MainMenuCustomElement>
                ),
            },
            {
                id: "flexSpacer",
                priority: 100500,
                render: () => <FlexSpacer priority={100500} key="spacer" />,
            },
            { id: "avatar", priority: 2, render: renderAvatar },
            // {
            //     id: "globalMenu",
            //     priority: 100,
            //     render: (p: { id: React.Key | null | undefined }) => (
            //         <GlobalMenu key={p.id} />
            //     ),
            // },
            // {
            //     id: "globalMenu",
            //     priority: 100500,
            //     render: () => <GlobalMenu key="globalMenu" />,
            // },
        ].filter((i) => !!i);
    };

    return <MainMenu cx={css.root} items={getMainMenuItems()}></MainMenu>;
}
