import React, { useContext, useMemo } from "react";
import { useLocalStorage } from "./useLocalStorage";

import { AppUserConfig, AuthenticateRequest } from "src/data/apiDefinition";

export type TAppUserContext = {
    user?: AppUserConfig | null;
    authenticate: (user: AuthenticateRequest) => void;
    removeUser: () => void;
    //refreshToken() => void; ?
};

/***********************/
// 1. Create
/***********************/
const AppUserContext = React.createContext<TAppUserContext | null>(null);

/***********************/
// 2. Use
/***********************/
export const useAppUser = () => {
    return useContext(AppUserContext);
};

/***********************/
// 3. Provide
/***********************/
export const AppUserProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [user, setUser] = useLocalStorage<AppUserConfig | null>(
        "iks_u",
        null,
    );

    const authenticate = (data: AppUserConfig) => {
        setUser(data);
    };

    const removeUser = () => {
        setUser(null);
    };

    // const value = useMemo(
    //     () => ({
    //         user,
    //         authenticate,
    //         removeUser,
    //     }),
    //     [user]
    // );

    return (
        <AppUserContext.Provider value={{ user, authenticate, removeUser }}>
            {children}
        </AppUserContext.Provider>
    );
};
