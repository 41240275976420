import React, { useEffect, useState } from "react";
import { useUuiContext, UuiContexts } from "@epam/uui-core";
import { DashboardItemCard } from "./DashboardItemCard";
import { AppFooter, AppHeader, AppHeaderUser, Page } from "../common";
import { dashboardItems } from "./structure";
import { useAppUser } from "../helpers/appUser";
import {
    AppUserConfig,
    AppUserProfile,
    AuthenticateRequest,
    TApi,
} from "src/data/apiDefinition";

import css from "./DashboardPage.module.scss";

export const DashboardPage = () => {
    const title = "";
    const userContext = useAppUser();
    const svc = useUuiContext<TApi, UuiContexts>();
    const [userProfile, setUserProfile] = useState<AppUserProfile | null>(null);

    useEffect(() => {
        const fetchUserProfile = async () => {
            var token = userContext?.user?.token;
            if (token) {
                svc.api
                    .getUserProfile({ token: token })
                    .then((data) => {
                        if (data?.email) {
                            setUserProfile(data);
                        }
                    })
                    .catch((error) => {
                        userContext?.removeUser();
                        svc.uuiRouter.redirect({ pathname: "/" });
                    });
            } else {
                svc.uuiRouter.redirect({ pathname: "/" });
            }
        };
        fetchUserProfile();
    }, []);

    const clearUserProfile = () => {
        userContext?.removeUser();
        svc.uuiRouter.redirect({ pathname: "/" });
    };

    const renderHeader = React.useCallback(() => {
        return (
            <AppHeader
                logoUrl="logo_1.png"
                userProfile={userProfile}
                userProfileClear={clearUserProfile}
            />
        );
    }, [userProfile]);

    const renderFooter = React.useCallback(() => {
        return <AppFooter />;
    }, []);

    const pageContent = (
        <div className={css.navPage}>
            <div className={css.navTitle}>{title}</div>
            <div className={css.navCards}>
                {dashboardItems.map((item) => {
                    return (
                        <DashboardItemCard
                            dashboardItem={item}
                            key={item.name}
                        />
                    );
                })}
            </div>
        </div>
    );
    return (
        <Page
            contentCx={css.root}
            renderHeader={renderHeader}
            renderFooter={renderFooter}
            isLoginPage={false}
        >
            {pageContent}
        </Page>
    );
};
