import { useAppUser } from "../helpers/appUser";
import { IHasChildren } from "@epam/uui-core";
import { svc } from "../services";

export function AppProtectedRoute(props: IHasChildren) {
    const { children } = props;
    const appUser = useAppUser();
    const isUserAuthenticated =
        appUser && appUser.user && appUser.user.token !== null;

    if (!isUserAuthenticated) {
        svc.uuiRouter?.redirect({ pathname: "/" });
    }
    return <>{children}</>;
}
