import { Text } from "@epam/uui";
import { FlexRow } from "@epam/promo";
import dayjs from "dayjs";
import css from "./AppFooter.module.scss";

interface IAppFooterProps {}

export function AppFooter(props: IAppFooterProps) {
    return (
        <div className={css.layout}>
            <FlexRow cx={css.footer}>
                <Text cx={css.copyright}>
                    © {dayjs().year()} - IKS Online - Risk Management Suite
                </Text>
            </FlexRow>
        </div>
    );
}
