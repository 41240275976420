import React from "react";
import { LandingPage } from "./landing/LandingPage";
import { Outlet, Route, Routes } from "react-router";
import { AppTheme } from "./helpers/appTheme";
import { getCurrentTheme } from "./helpers";
import { Snackbar } from "@epam/uui";
import { Modals, PortalRoot } from "@epam/uui-components";
import { DragGhost } from "@epam/uui-core";
import { AppUserProvider } from "./helpers/appUser";
import { DashboardPage } from "./dashboard";
import { RiskPage } from "./risk/RiskPage";
import { AppProtectedRoute } from "./common/AppProtectedRoute";

function App() {
    return (
        <Routes>
            <Route Component={AppLayout}>
                <Route path="/" element={<LandingPage />} />
                <Route
                    path="/dashboard"
                    element={
                        <AppProtectedRoute>
                            <DashboardPage />
                        </AppProtectedRoute>
                    }
                />
                <Route
                    path="/risk"
                    element={
                        <AppProtectedRoute>
                            <RiskPage />
                        </AppProtectedRoute>
                    }
                />
            </Route>
        </Routes>
    );
}

function AppLayout() {
    const theme = getCurrentTheme();

    return (
        <AppTheme key={`${theme}`}>
            <AppUserProvider>
                <Outlet />
                <Snackbar />
                <Modals />
                <DragGhost />
                <PortalRoot />
            </AppUserProvider>
        </AppTheme>
    );
}

export default App;
