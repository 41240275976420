/* global RequestInit */
import { getDemoApi } from "./demoApi";
import type {
    CommonContexts,
    UuiContexts,
    ITablePreset,
    IProcessRequest,
} from "@epam/uui-core";

export const delay = (ms: number = 1): Promise<void> =>
    new Promise((resolve) => {
        setTimeout(resolve, ms);
    });

export interface IAppUserRequest {
    token: string;
}

export interface IRiskRequest {
    token: string;
    searchText: string;
}

export interface IRiskRequestResponse {
    data?: IRiskData[];
}

export interface IRiskData {
    __typename: "Risk";
    id: number;
    number: string;
    type: string;
    isImportant?: boolean;
    description?: boolean;
    iksObjective?: string;
    isActive?: boolean;
    grossAssessment?: IRiskGrossAssessmentData;
    netAssessment?: IRiskNetAssessmentData;
    controls?: IRiskControlData[];
    responsibleUser?: IRiskUserData;
}

export interface IRiskAssessmentLevelData {
    id?: number;
    name?: string;
}

export interface IRiskGrossAssessmentData {
    damageLevel?: IRiskAssessmentLevelData;
    eventLevel?: IRiskAssessmentLevelData;
    riskLevel?: IRiskAssessmentLevelData;
    riskIndicatorText?: string;
    existingMeasures?: string;
}

export interface IRiskNetAssessmentData {
    damageLevel?: IRiskAssessmentLevelData;
    eventLevel?: IRiskAssessmentLevelData;
    riskLevel?: IRiskAssessmentLevelData;
    initiatedOrPlannedMeasures?: string;
}

export interface IRiskControlData {
    id?: number;
    name?: string;
    periodicity?: IRiskPeriodicityData;
    responsibleUser?: IRiskUserData;
}

export interface IRiskPeriodicityData {
    id?: number;
    name?: string;
}

export interface IRiskUserData {
    id?: string;
    abbreviation?: string;
}

export interface GetCodeParams {
    path: string;
}

export interface AuthenticateRequest {
    email?: string;
    password?: string;
}

export interface AppUserConfig {
    token?: string;
    displayName?: string;
    email?: string;
    abbreviation?: string;
}

export interface AppUserProfile {
    displayName?: string;
    email?: string;
    abbreviatin?: string;
    reportsToBePerformed?: number;
    controlsToBePerformed?: number;
    userCanViewRisks?: boolean;
    userCanAddControls?: boolean;
    userIsSuperUser?: boolean;
    userIsAuditor?: boolean;
}

// [REV1][SHOULD: use this for error handling]
export interface ApiProblemDetails {}

export interface GetCodeResponse {
    filePath: string;
    gitUrl: string;
    raw: string;
    highlighted: string;
}

interface GetApiParams {
    processRequest: IProcessRequest;
    origin?: string;
    fetchOptions?: RequestInit;
}

export function getApi({
    processRequest,
    origin = "",
    fetchOptions,
}: GetApiParams) {
    const processRequestLocal: IProcessRequest = (
        url,
        method,
        data,
        options,
    ) => {
        const opts = fetchOptions ? { fetchOptions, ...options } : options;
        return processRequest(url, method, data, opts);
    };

    return {
        demo: getDemoApi(processRequestLocal, origin),
        form: {
            validateForm: <FormState>(formState: FormState) =>
                processRequestLocal(
                    origin.concat("/api/form/validate-form"),
                    "POST",
                    formState,
                ),
        },
        errors: {
            status: (status: number) =>
                processRequestLocal(
                    origin.concat(`/api/error/status/${status}`),
                    "POST",
                ),
            setServerStatus: (status: number) =>
                processRequestLocal(
                    origin.concat(`/api//error/set-server-status/${status}`),
                    "'POST'",
                ),
            mock: () =>
                processRequestLocal(origin.concat("/api/error/mock"), "GET"),
            authLost: () =>
                processRequestLocal(
                    origin.concat("/api/error/auth-lost"),
                    "POST",
                ),
        },
        getHealth() {
            return processRequestLocal(origin.concat("api/healthz"), "GET");
        },
        authenticateUser(req: AuthenticateRequest) {
            return processRequestLocal<AppUserConfig>(
                origin.concat("/api/auth/login"),
                "POST",
                req,
                {
                    errorHandling: "manual",
                },
                // [REF1][SHOULD: pass the above as notification and error message in the format API expects]
            );
        },
        getUserProfile(req: IAppUserRequest) {
            var { token } = req;
            return processRequestLocal<AppUserProfile>(
                origin.concat("/api/auth/me"),
                "GET",
                null,
                {
                    fetchOptions: {
                        credentials: undefined,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                        },
                    },
                },
                // [REF1][SHOULD: pass the above as notification and error message in the format API expects]
            );
        },
        getUserRisks(req: IRiskRequest) {
            var { token, searchText } = req;
            return processRequestLocal<IRiskData>(
                origin.concat("/api/v1/risks"),
                "POST",
                {
                    searchText: searchText,
                    filter: {},
                },
                {
                    fetchOptions: {
                        credentials: undefined,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                        },
                    },
                },
                // [REF1][SHOULD: pass the above as notification and error message in the format API expects]
            );
        },
        getChangelog() {
            return processRequestLocal(
                origin.concat("/api/get-changelog"),
                "GET",
            );
        },
        getCode(rq: GetCodeParams) {
            return processRequestLocal<GetCodeResponse>(
                origin.concat("/api/get-code"),
                "POST",
                rq,
            );
        },
        getProps() {
            return processRequestLocal(origin.concat("/api/get-props/"), "GET");
        },
        presets: {
            async getPresets(): Promise<ITablePreset[]> {
                await delay(500);
                return Promise.resolve(
                    JSON.parse(localStorage.getItem("presets") ?? "[]") ?? [],
                );
            },
            async createPreset(preset: ITablePreset): Promise<number> {
                await delay(500);
                const presets = (JSON.parse(
                    localStorage.getItem("presets") ?? "[]",
                ) ?? []) as ITablePreset[];
                const newId = presets.length
                    ? Math.max.apply(
                          null,
                          presets.map((p) => p.id ?? 0),
                      ) + 1
                    : 1;
                preset.id = newId;
                localStorage.setItem(
                    "presets",
                    JSON.stringify([...presets, preset]),
                );
                return Promise.resolve(newId);
            },
            async updatePreset(preset: ITablePreset): Promise<void> {
                await delay(500);
                const presets = (JSON.parse(
                    localStorage.getItem("presets") ?? "[]",
                ) ?? []) as ITablePreset[];
                presets.splice(
                    presets.findIndex((p) => p.id === preset.id),
                    1,
                    preset,
                );
                localStorage.setItem("presets", JSON.stringify(presets));
                return Promise.resolve();
            },
            async deletePreset(preset: ITablePreset): Promise<void> {
                await delay(500);
                const presets = (JSON.parse(
                    localStorage.getItem("presets") ?? "[]",
                ) ?? []) as ITablePreset[];
                presets.splice(
                    presets.findIndex((p) => p.id === preset.id),
                    1,
                );
                localStorage.setItem("presets", JSON.stringify(presets));
                return Promise.resolve();
            },
        },
    };
}

export type TApi = ReturnType<typeof getApi>;
export const svc: Partial<CommonContexts<TApi, UuiContexts>> = {};
