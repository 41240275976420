import React, {
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import isEqual from "react-fast-compare";
import {
    DataColumnProps,
    DataSourceState,
    useAsyncDataSource,
    useUuiContext,
    UuiContexts,
} from "@epam/uui-core";
import {
    DataTable,
    FlexRow,
    FlexSpacer,
    IconContainer,
    Paginator,
    Panel,
    Text,
} from "@epam/uui";
import { AppFooter, AppHeader, AppHeaderUser, Page } from "../common";
import { useAppUser } from "../helpers/appUser";
import {
    AppUserConfig,
    AppUserProfile,
    AuthenticateRequest,
    IRiskData,
    TApi,
} from "src/data/apiDefinition";

import css from "./RiskPage.module.scss";
import { ReactComponent as AccountIcon24 } from "@epam/assets/icons/common/flag-24.svg";

export const RiskPage = () => {
    const userContext = useAppUser();
    const svc = useUuiContext<TApi, UuiContexts>();
    const [userProfile, setUserProfile] = useState<AppUserProfile | null>(null);
    const [value, onValueChange] = useState({});
    const [state, setState] = useState<DataSourceState>({
        page: 1,
        pageSize: 10,
    });

    const setTableState = useCallback(
        (newState: SetStateAction<DataSourceState>) => {
            setState((currentState) => {
                const updatedState =
                    typeof newState === "function"
                        ? newState(currentState)
                        : newState;
                const isFilterChanged = !isEqual(
                    currentState.filter,
                    updatedState.filter,
                );
                const isSearchChanged =
                    currentState.search !== updatedState.search;
                const isSortingChanged = !isEqual(
                    currentState.sorting,
                    updatedState.sorting,
                );

                if (isFilterChanged || isSearchChanged || isSortingChanged) {
                    return { ...updatedState, checked: [] };
                }
                return updatedState;
            });
        },
        [],
    );

    useEffect(() => {
        const fetchUserProfile = async () => {
            var token = userContext?.user?.token;
            if (token) {
                svc.api
                    .getUserProfile({ token: token })
                    .then((data) => {
                        if (data?.email) {
                            setUserProfile(data);
                        }
                    })
                    .catch((error) => {
                        userContext?.removeUser();
                        svc.uuiRouter.redirect({ pathname: "/" });
                    });
            } else {
                svc.uuiRouter.redirect({ pathname: "/" });
            }
        };
        fetchUserProfile();
    }, []);

    const dataSource = useAsyncDataSource<IRiskData, number, unknown>(
        {
            // Provide api which returns Promise with items for table. If you want to pass just array of items, look to the ArrayDataSource
            api: () => {
                var token = userContext?.user?.token ?? "";
                return svc.api
                    .getUserRisks({ token: token, searchText: "" })
                    .then((r: any) => r.data);
            },
            getId: (p) => p.id, // Default: p => p.id
        },
        [],
    );

    const view = dataSource.useView(value, onValueChange, {
        getRowOptions: () => ({
            checkbox: { isVisible: true },
            isSelectable: true,
        }), // Provide metadata options about row. Go to the "DataRowOptions" interface, to see the full list of possible options.
        getFilter: () => (item) => !!item.type, // Provide filter callback, if your need to apply filtering for rows.
        // By default sorting will be applied by column 'key' field. If you need another behavior, pass sortBy callback, which will return item field for sorting;
        sortBy: (item, sorting) => {
            switch (sorting.field) {
                case "id":
                    return item.id;
                case "number":
                    return item.number;
                case "isImportant":
                    return item.isImportant;
                case "type":
                    return item.type;
            }
        },
        // Also you can provide more options if you need:
        // getSearchFields(item: Product): string[] { return [item.Name, item.Color] }, Function that return array of item fields by which search will applies;
        // isFoldedByDefault(item: Product): boolean { return true }, Defined if row is folded by default for first render or not;
        // cascadeSelection: true, Default: false; If you have tree structure, you can define: cascade selections from parent to each child or only select parent;
    });
    const listProps = view.getListProps();
    const riskColumns: DataColumnProps<IRiskData>[] = useMemo(
        () => [
            {
                key: "id",
                caption: "Id",
                render: (product) => (
                    <Text color="secondary">{product.id}</Text>
                ),
                isSortable: true,
                isAlwaysVisible: true,
                width: 120,
            },
            {
                key: "number",
                caption: "Nr.",
                render: (product) => (
                    <Text color="secondary" fontWeight="600">
                        {product.number}
                    </Text>
                ),
                isSortable: true,
                width: 100,
            },
            {
                key: "isImportant",
                caption: "Key?",
                render: (product) => {
                    if (product.isImportant) {
                        return (
                            <IconContainer
                                icon={AccountIcon24}
                                style={{ fill: "#ccc" }}
                            />
                        );
                    } else {
                        return (
                            <IconContainer
                                icon={AccountIcon24}
                                style={{ fill: "#88CC00" }}
                            />
                        );
                    }
                },
                isSortable: true,
                width: 80,
            },
            {
                key: "description",
                caption: "Beschreibung Risiko",
                render: (product) => <Text>{product.description}</Text>,
                width: 250,
            },
            {
                key: "type",
                caption: "Prüfgebiete / Prüffelder / Themen",
                render: (product) => <Text>{product.type}</Text>,
                width: 200,
                isSortable: true,
            },
        ],
        [],
    );

    const clearUserProfile = () => {
        userContext?.removeUser();
        svc.uuiRouter.redirect({ pathname: "/" });
    };

    const renderHeader = React.useCallback(() => {
        return (
            <AppHeader
                logoUrl="logo_1.png"
                userProfile={userProfile}
                userProfileClear={clearUserProfile}
            />
        );
    }, [userProfile]);

    const renderFooter = React.useCallback(() => {
        return <AppFooter />;
    }, []);

    const pageContent = (
        <Panel background="surface-main" shadow cx={css.container}>
            <DataTable
                {...view.getListProps()}
                getRows={view.getVisibleRows}
                value={value}
                onValueChange={onValueChange}
                columns={riskColumns}
                headerTextCase="upper"
                headerSize="48"
                columnsGap="12"
            />
            <FlexRow size="36" padding="12">
                <FlexSpacer />
                <Paginator
                    value={state.page ?? 1}
                    onValueChange={(newPage) =>
                        setTableState({
                            ...state,
                            page: newPage,
                            scrollTo: { index: 0 },
                        })
                    }
                    totalPages={Math.ceil(
                        (listProps.totalCount ?? 0) / (state.pageSize || 10),
                    )}
                />
                <FlexSpacer />
            </FlexRow>
        </Panel>
    );
    return (
        <Page
            contentCx={css.root}
            renderHeader={renderHeader}
            renderFooter={renderFooter}
            isLoginPage={false}
        >
            {pageContent}
        </Page>
    );
};
