import { ThemeBaseParams, builtInThemes, ThemeId } from "./themes";

export interface AppContext {
    themes: ThemeId[];
    themesById: Record<ThemeId, ThemeBaseParams>;
}

export async function getThemeContext() {
    const allThemes = [...builtInThemes];
    const themesById = allThemes.reduce<Record<ThemeId, ThemeBaseParams>>(
        (acc, t) => {
            acc[t.id] = t;
            return acc;
        },
        {},
    );

    return {
        themes: Object.keys(themesById),
        themesById,
    };
}
